//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaAvatar from '@/components/SanghaAvatar.vue';
import { Posts } from '@/services/api.service';
import { NUMBER_OF_RESULTS_PER_PAGE } from '@/services/config';

export default {
  name: 'UserReactionListModal',
  data() {
    return {
      showModal: true,

      currentPage: 0,
      hasNextPage: false,

      modalList: [],
      reactionType: null,
      postReactions:[],
      postReactionsCount:{
        REACTION_1: 0,
        REACTION_2: 0,
        REACTION_3: 0,
        REACTION_4: 0,
        REACTION_5: 0,
      },

      inProgress: null,
      currentOptions: {
        limit: NUMBER_OF_RESULTS_PER_PAGE,
        offset: 0,
      },

      buttonIntersectionOptions: {
        handler: this.onLoadMoreButtonIntersection,
      },
      intersectionStarted: false,
    };
  },
  props: [
    'show',
    'color',
    'title',
    'initialReaction',
    'initialPostId',
  ],
  components: {
    SanghaAvatar,
  },
  methods: {
    searchUser(options) {
      if (options) {
        this.currentOptions = { ...this.currentOptions, ...options };
      }

      this.currentOptions.postId = this.initialPostId;

      this.inProgress = true;
      Posts.listReactions(this.currentOptions).then(
        (res) => {
          this.postReactions = res.reactions.map(i => i.reaction);
          this.postReactions.forEach((i) => {
            const postReactCnt = res.reactions.filter((x) => x.reaction === i)[0].count;
            this.postReactionsCount[i] = postReactCnt;
          })
            
          
          let currentReaction = res.reactions.filter((i) => i.reaction === this.reactionType)[0];
          
          if (!currentReaction) {
            this.modalList = [];
            this.hasNextPage = false;
            this.inProgress = false;
            return;
          }
          
          if (options && options.offset > 0) {
            const usersFiltered = currentReaction.users.filter((obj) => { 

              const mll = this.modalList.filter((mlItem) => {
                return mlItem.id == obj.id
              })
              return !mll.length
            });

            this.modalList = [
              ...this.modalList,
              ...(usersFiltered || []),
            ];
          } else {
            this.modalList = currentReaction.users;
          }
          this.hasNextPage = currentReaction.hasNextPage;
          this.inProgress = false;
          setTimeout(() => {
            this.intersectionStarted = true;
          }, 500);
        },
        () => {
          this.inProgress = false;
        }
      );
    },
    loadNextPage() {
      this.currentPage = this.currentPage + 1;
      const options = {
        offset: NUMBER_OF_RESULTS_PER_PAGE * this.currentPage,
      };
      this.searchUser(options);
    },
    loadNextPageIntersection(obj) {
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0)
        this.loadNextPage();
    },
    onLoadMoreButtonIntersection(obj) {
      if (!this.intersectionStarted) {
        return;
      }
      if (!this.inProgress && obj.isIntersecting && obj.intersectionRatio > 0) {
        this.intersectionStarted = false;
        this.loadNextPage();
      }
    },

    handleClick(user) {
      this.$emit('close', user);
    },
  },
  watch: {
    showModal() {
      this.$emit('close', null);
    },
    initialReaction(v) {
      if (v) this.reactionType = v;
    },
    reactionType() {
      this.modalList = [];
      this.searchUser({ offset:0 });
    }
  },
  computed: {},
  mounted() {
    if (this.initialReaction) this.reactionType = this.initialReaction;
    this.searchUser();
  },
};
